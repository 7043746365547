






























































import { Component } from 'vue-property-decorator';
import MyStrategiesToolbar from './MyStrategiesToolbar.vue';

@Component
export default class MyStrategiesBottomToolbar extends MyStrategiesToolbar {
}
